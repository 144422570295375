<template src='./ArrobaMedellinVerticalCardNetworking.html'></template>

<script>
export default {
  props: {
    img: { type: String },
    imgDescription: { type: String, default: "Imagen de sala" },
    title: { type: String },
    subtitle: { type: String },
    subtitle2: { type: String },
    body: { type: [String, Object], required: false },
    progress: { type: [Number, String]},
    typeCard: { type: Object },
    url: { type: String },
    aforo: { type: Number , required: false},
    person: { type: Number, required: false},
    room: { type: Object }
  },
  name: 'arroba-medellin-vertical-card-networking',
  data() {
    return {
      buttonText: "",
      disableButton: ""
    }
  },
  methods: {
    pushButton() {
      this.$emit('verticalCardClickButton', { typeButton: this.buttonText, url: this.url, room: this.room})
    },
    definedText() {
      if(this.aforo === this.person) {
        this.buttonText = 'Sala llena'
        this.disableButton = true
      } else {
        this.buttonText = 'Ingresar'
        this.disableButton = false
      }
    },
  },
    created() {
      this.definedText()
    },
    computed: {
      imgUrl() {
        return this.img ? this.img : 'https://storage.googleapis.com/arrobamedellin.appspot.com/Img_default.png';
      }
    }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinVerticalCardNetworking.scss"></style>
