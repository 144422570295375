<template src="./Networking.html"></template>

<script>
import { mapGetters } from "vuex";
import { SwiperSlide } from "vue-awesome-swiper";
import networkingService from "@/services/Networking";
import profileService from "@/services/Profile";
import networkingUsersService from "@/services/NetworkingUsers";
import categoryService from "@/services/Category";
import ArrobaMedellinVerticalCardNetworking from "@/components/ArrobaMedellinVerticalCardNetworking/ArrobaMedellinVerticalCardNetworking";
import swiperLargeOption from "@/utils/swiper-large-option";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import tutorial from "@/mixins/tutorial";
import ArrobaMedellinArrobitaNoData from "../../components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import { required } from "vuelidate/lib/validators";
import networkingUsersTeacherServices from "../../services/NetworkingUsersTeacher";

export default {
  name: "Networking",
  mixins: [tutorial],
  components: {
    SwiperSlide,
    ArrobaMedellinVerticalCardNetworking,
    ArrobaMedellinSeeker,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      isComponentModalActive: false,
      errorMessage: false,
      room: {
        name: null,
        description: null,
        category: null,
      },
      roomSelect: {},
      isActive: "Tus seguidores",
      swiperLargeOptionCreated: {
        ...swiperLargeOption,
        breakpoints: {
          360: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            centeredSlides: true,
          },
          643: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            centeredSlides: true,
          },
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            centeredSlides: true,
          },
          1216: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            centeredSlides: false,
          },
        },
      },
      swiperLargeOption: swiperLargeOption,
      typeCard: { button: "button--purple", size: "small" },
      categories: [],
      availableRoom: [],
      userInfo: {},
      itemsToShowSliderTop: Number(1),
      itemsToShowSliderBottom: Number(1),
      role: "",
    };
  },
  validations: {
    room: {
      name: { required },
      category: { required },
      description: { required },
    },
  },
  created() {
    this.getNetworkingInformation();
    this.role = this.$store.state.User.user.role.name;
  },
  mounted() {
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
    this.viewTutorial();
  },
  methods: {
    updateData() {
      (this.room.name = null),
        (this.room.description = null),
        (this.room.category = null);
    },
    async getNetworkingSearch(word) {
      try {
        if (!word) {
          this.availableRoom = await networkingService.getNetworkings();
          this.errorMessage = false;
        } else {
          this.availableRoom = await networkingService.searchNetworking(word);
          if (this.availableRoom.length === 0) this.errorMessage = true;
        }
      } catch (e) {
        console.error("error", e);
      }
    },

    async getNetworkingInformation() {
      try {
        this.availableRoom = await networkingService.getNetworkings();
        this.userInfo = await profileService.getUserData();
        this.categories = await categoryService.getCategories();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: Networking/getNetworkingInformation()", e);
      }
    },

    async saveNetworkingUsers(idNetworking) {
      return await networkingUsersService.createdNetworkingUsers({
        networkingId: idNetworking,
      });
    },

    async verticalCardClickButton(button) {
      const idNetworking = button.room.id;
      const userNetworking = await this.saveNetworkingUsers(idNetworking);
      if (userNetworking) {
        this.$router.push({
          name: "Meet",
          params: {
            idNetworking: idNetworking,
            isPrivateRoom: false,
            isUrl: false,
          },
        });
      }
    },

    async createdNetworking() {
      try {
        const networkingCreate = await networkingService.createNetworking(
          this.room
        );
        const userNetworking = await this.saveNetworkingUsers(
          networkingCreate.id
        );
        if (userNetworking) {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "La sala se ha creado con éxito!",
            type: "is-success",
          });
          this.updateData();
          this.$router.push({
            name: "Meet",
            params: {
              idNetworking: networkingCreate.id,
              isPrivateRoom: false,
              isUrl: false,
            },
          });
        }
      } catch (e) {
        this.isComponentModalActive = false;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message ?? "Se presentó un error",
          type: "is-danger",
        });
        this.updateData();
        console.error(e);
      }
    },
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShowSliderBottom = Number(1);
      } else if (window.innerWidth < 850) {
        this.itemsToShowSliderBottom = Number(2);
      } else if (window.innerWidth < 1224) {
        this.itemsToShowSliderBottom = Number(3);
      } else if (window.innerWidth < 1580) {
        this.itemsToShowSliderBottom = Number(4);
      } else if (window.innerWidth > 1580) {
        this.itemsToShowSliderBottom = Number(5);
      }
    },
    viewTutorial() {
      this.dataTutorial("networking");
    },
    async privateRoom() {
      try {
        const infoConsultPrivateRoom =
          await networkingService.consultPrivateRoom();
          await networkingUsersTeacherServices.createdNetworkingUsersTeacher({
            networkingId: infoConsultPrivateRoom.networkingTeacher.id,
          });
        this.$router.push({
          name: "Meet",
          params: {
            idNetworking: infoConsultPrivateRoom.networkingTeacher.id,
            isPrivateRoom: true,
            isUrl: false,
          },
        });
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "¡Ingresando a la sala con éxito!",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: error.response.data.message ?? "Se presentó un error",
          type: "is-danger",
        });
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    roleValidation() {
      return ["Teacher", "TeacherInvestigator"].some((a) => a === this.role);
    },
    roleValidationNoAccess() {
      return ["Invited", "University", "AdministratorCall"].some(
        (a) => a === this.role
      );
    },
    roleValidationInvited() {
      return ["Invited"].some((a) => a === this.role);
    },
  },
  user: function (value) {
    this.userInfo = value;
  },
};
</script>

<style lang="scss" src="./Networking.scss" scoped></style>
