import { render, staticRenderFns } from "./ArrobaMedellinVerticalCardNetworking.html?vue&type=template&id=0bafb064&scoped=true&"
import script from "./ArrobaMedellinVerticalCardNetworking.vue?vue&type=script&lang=js&"
export * from "./ArrobaMedellinVerticalCardNetworking.vue?vue&type=script&lang=js&"
import style0 from "./ArrobaMedellinVerticalCardNetworking.scss?vue&type=style&index=0&id=0bafb064&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bafb064",
  null
  
)

export default component.exports